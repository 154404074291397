type NumberFormatConfig = {
  maximumFractionDigits?: number
}

// currency format
export const currencyF = (
  number: number,
  locale: string = 'zh-TW',
  config: NumberFormatConfig = { maximumFractionDigits: 0 }
) => `NT$${new Intl.NumberFormat(locale, config).format(number)}`

// number format
export const numberF = (
  number: number,
  config: NumberFormatConfig = { maximumFractionDigits: 2 }
) => new Intl.NumberFormat('zh-TW', config).format(number)

// Same effect to path.basename
// https://nodejs.org/api/path.html#pathbasenamepath-ext
export const basename = (path: string): string => {
  const arr = path.split('/')

  return arr[arr.length - 1]
}

const scrollOffsetY = (target: Element) => {
  if (typeof document === 'undefined') return 0

  return (
    target.getBoundingClientRect().top + (window.pageYOffset || window.scrollY)
  )
}

// scroll the element via native window.scroll
// https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll
export const scrollToElement = (
  selectors: string,
  options: {
    offsetX?: number
    offsetY?: number
  } = {}
) => {
  if (typeof document === 'undefined') return

  const target = document.querySelector(selectors)

  if (!target) return

  const y = scrollOffsetY(target)

  scroll({
    top: y + (options?.offsetY ?? 0),
    left: 0 + (options?.offsetX ?? 0),
    behavior: 'smooth',
  })
}
