import { BlogPageData } from './types'

const BlogPlainTemplate = ({
  post,
}: {
  post: BlogPageData['markdownRemark']
}) => (
  <article
    dangerouslySetInnerHTML={{
      __html: post.html,
    }}
  />
)

export default BlogPlainTemplate
