export enum BlogType {
  Viewer = 'blog:viewer',
  Plain = 'blog:plain',
}

export type Post = {
  id: string
  html: string
  excerpt: string
  frontmatter: {
    title: string
    date: string
    categories: string[]
    type: BlogType
  }
}

export type BlogPageData = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  markdownRemark: Post
  cover?: {
    childImageSharp: {
      id: string
      original: {
        src: string
      }
    }
  }
}

export type PageContext = {
  id: string
  slug: string
  next?: MarkdownRemark
  previous?: MarkdownRemark
}

export type MarkdownRemark = {
  id: string
  fields: {
    slug: string
  }
  frontmatter: {
    title: string
  }
}

export type MarkdownRemarkEdge = {
  node: MarkdownRemark
  next: MarkdownRemark | null
  previous: MarkdownRemark | null
}
