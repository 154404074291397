/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link, Text } from '@nextui-org/react'

const useSiteMetadata = (): Queries.BioQuery => {
  const data = useStaticQuery(
    graphql`
      query Bio {
        avatar: file(absolutePath: { regex: "/logo-text__black/" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 80, placeholder: TRACED_SVG)
          }
        }
        site {
          siteMetadata {
            slogan
            name {
              full
            }
          }
        }
      }
    `
  )
  return data
}

const Bio = () => {
  const data = useSiteMetadata()
  const { name, slogan } = data?.site?.siteMetadata || {}
  const image = data?.avatar?.childImageSharp?.gatsbyImageData
  const bioImage = image ? getImage(image) : null

  return (
    <div className="flex">
      {bioImage && (
        <GatsbyImage
          image={bioImage}
          alt={name?.full || ''}
          className="mr-3 border border-solid border-gray-300 rounded-full"
        />
      )}
      <div className="p-2">
        <Text size="1.125rem">
          <Link
            color="text"
            href={process.env.GATSBY_PUBLIC_SITES_ORIGIN ?? '/'}
          >
            {name?.full}
          </Link>
        </Text>
        <Text className="m-0 mt-1" color="$accents8">
          {slogan}
        </Text>
      </div>
    </div>
  )
}

export default Bio
