import { GalleryImageProps, ImageRender } from './types'
import ImageGrids from './ImageGrids'
import ImageSwiper from './ImageSwiper'
import ImageViewer, { useImageViewer } from './ImageViewer'

export type Props = {
  images: GalleryImageProps[]
  imageRender: ImageRender
  imageHeight?: number
}

export default ({ images, imageRender, imageHeight = 200 }: Props) => {
  const { visible, handleImageViewerOpen, handleImageViewerClose } =
    useImageViewer()

  return (
    <>
      <div className="w-full flex md:hidden">
        <ImageSwiper images={images} imageRender={imageRender} />
      </div>

      <div className="w-full md:flex hidden ">
        <ImageGrids
          images={images}
          imageRender={imageRender}
          imageHeight={imageHeight}
          handleImageViewerOpen={handleImageViewerOpen}
        />
      </div>

      <ImageViewer
        images={images}
        imageRender={imageRender}
        visible={visible}
        handleImageViewerClose={handleImageViewerClose}
      />
    </>
  )
}
