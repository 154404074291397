export type PostConfig = {
  slug: string
  ending: string
  images: Array<{ filename: string; description: string }>
}

type PostConfigs = {
  [key: string]: {
    slug: string
    ending: string
    images: Array<{ filename: string; description: string }>
  }
}

const configs: PostConfigs = {
  '/jing-mei-883/': {
    slug: '/jing-mei-883/',
    ending: '',
    images: [
      { filename: 'jing-mei-883-001.jpg', description: '木工進場' },
      { filename: 'jing-mei-883-002.jpg', description: '天花板工程' },
      { filename: 'jing-mei-883-003.jpg', description: '輕隔間工程' },
      { filename: 'jing-mei-883-004.jpg', description: '空調包管' },
      { filename: 'jing-mei-883-005.jpg', description: '燈具開孔' },
      { filename: 'jing-mei-883-006.jpg', description: '油漆進場' },
      { filename: 'jing-mei-883-007.jpg', description: '油漆完成' },
      {
        filename: 'jing-mei-883-008.jpg',
        description: '油漆完成（窗外就是我提到的電線桿）',
      },
      { filename: 'jing-mei-883-009.jpg', description: '燈具開孔' },
      { filename: 'jing-mei-883-010.jpg', description: '空調安裝' },
      { filename: 'jing-mei-883-011.jpg', description: '系統衣櫃' },
      { filename: 'jing-mei-883-012.jpg', description: '系統衣櫃＋書桌' },
      { filename: 'jing-mei-883-013.jpg', description: '系統衣櫃＋書桌' },
      {
        filename: 'jing-mei-883-014.jpg',
        description: '書桌和床頭櫃連為一體，加強空間延伸感',
      },
      { filename: 'jing-mei-883-015.jpg', description: '搭配掀床增加收納空間' },
      { filename: 'jing-mei-883-016.jpg', description: '多處系統櫃收納空間' },
      {
        filename: 'jing-mei-883-017.jpg',
        description: '書桌與床頭櫃切斜角壁面尖角撞傷',
      },
      { filename: 'jing-mei-883-018.jpg', description: '獨立工作區' },
      { filename: 'jing-mei-883-019.jpg', description: '自製的油漆畫' },
      { filename: 'jing-mei-883-020.jpg', description: '小房間也配置了雙人床' },
      {
        filename: 'jing-mei-883-021.jpg',
        description: '小房間一樣擁有大面採光',
      },
      { filename: 'jing-mei-883-022.jpg', description: '白天完全不需開燈' },
      { filename: 'jing-mei-883-023.jpg', description: '客廳' },
      { filename: 'jing-mei-883-024.jpg', description: '廚房前斜放的吧台' },
      {
        filename: 'jing-mei-883-025.jpg',
        description:
          '因為原先格局實在沒有適合放置冰箱的位置，最後設計在吧台下方，另外為了廚房良好的動線，我們將吧台設計成斜的，同樣提供額外的收納空間',
      },
      { filename: 'jing-mei-883-026.jpg', description: '建商附的系統廚具' },
      { filename: 'jing-mei-883-027.jpg', description: '衛浴改投射型照明' },
      { filename: 'jing-mei-883-028.jpg', description: '浴缸上增加了投射燈' },
    ],
  },
  '/idle-old-house-renovation/': {
    slug: '/idle-old-house-renovation/',
    ending: '耗時不到兩個月，且整體工程僅花了 60 萬初，完成這個老屋翻新！',
    images: [
      {
        filename: '閒置老屋改造02.jpg',
        description: '古老的木作櫃體間隔出客廳和廚房，影響整個房屋觀感',
      },
      {
        filename: '閒置老屋改造03.jpg',
        description: '主臥室的老舊櫃子和不堪用的窗框',
      },
      {
        filename: '閒置老屋改造04.jpg',
        description: '破壞整個窗戶的窗型冷氣和脫落的壁紙',
      },
      {
        filename: '閒置老屋改造05.jpg',
        description: '老舊的廚房和通往陽台脆化的木門',
      },
      { filename: '閒置老屋改造06.jpg', description: '阿嬤家風味的浴室' },
      { filename: '閒置老屋改造07.jpg', description: '拆除進場' },
      { filename: '閒置老屋改造08.jpg', description: '拆除後現場' },
      { filename: '閒置老屋改造09.jpg', description: '拆除清運後寬敞的大空間' },
      {
        filename: '閒置老屋改造10.jpg',
        description: '浴室隔間牆外側滲水水泥打除',
      },
      { filename: '閒置老屋改造11.jpg', description: '窗框滲水處理' },
      { filename: '閒置老屋改造12.jpg', description: '泥作木作同時進行' },
      { filename: '閒置老屋改造13.jpg', description: '滲水牆面重新抹水泥' },
      { filename: '閒置老屋改造14.jpg', description: '衛浴舊磁磚剔除' },
      { filename: '閒置老屋改造15.jpg', description: '衛浴泥作打底' },
      { filename: '閒置老屋改造16.jpg', description: '新窗框安裝' },
      { filename: '閒置老屋改造17.jpg', description: '電路重新配置佈線' },
      { filename: '閒置老屋改造18.jpg', description: '舊壁紙剔除' },
      { filename: '閒置老屋改造19.jpg', description: '貼新壁紙' },
      { filename: '閒置老屋改造20.jpg', description: '新牆面油漆' },
      { filename: '閒置老屋改造21.jpg', description: '工業風燈具安裝' },
      { filename: '閒置老屋改造22.jpg', description: '廚房舊磁磚以油性漆翻新' },
      { filename: '閒置老屋改造23.jpg', description: '衛浴安裝' },
      { filename: '閒置老屋改造24.jpg', description: '浴門安裝' },
      { filename: '閒置老屋改造25.jpg', description: '貼地板' },
      { filename: '閒置老屋改造26.jpg', description: '窗簾安裝' },
      { filename: '閒置老屋改造27.jpg', description: '簡易廚房' },
      { filename: '閒置老屋改造28.jpg', description: '陽台洗衣機' },
      { filename: '閒置老屋改造29.jpg', description: '電視牆＆餐廳' },
      { filename: '閒置老屋改造30.jpg', description: '掛上自己畫的油漆作品' },
      { filename: '閒置老屋改造31.jpg', description: '寬敞簡單的客廳' },
      { filename: '閒置老屋改造32.jpg', description: '從餐廳看客廳' },
    ],
  },
  '/bathroom-kitchen-balcony-renovate-sharing/': {
    slug: '/bathroom-kitchen-balcony-renovate-sharing/',
    ending: '',
    images: [
      {
        filename: '1.JPG',
        description: '廁所改造案例: 因原先的防水層沒有失效，用塗料來美化',
      },
      { filename: '2.jpg', description: '' },
      { filename: '3.JPG', description: '' },
      {
        filename: '4.JPG',
        description: '廚房改造前: 將老舊的流理臺和木櫃拆除',
      },
      {
        filename: '5.JPG',
        description: '',
      },
      {
        filename: '6.jpg',
        description:
          '燈具的替換，增加明亮度外，也兼具裝飾品的功能，把拆除木櫃的空間，換成餐桌，讓廚房也是餐廳',
      },
      {
        filename: '7.jpg',

        description: '改造完成後，你甚至猜不到這是同一個空間',
      },
      { filename: '8-1.JPG', description: '改造前的陽台和流理臺' },
      { filename: '8-2.JPG', description: '' },
      {
        filename: '9.jpg',
        description:
          '重新粉刷牆面，一片一片將木板拼上，換上軌道燈 早上有陽光普照，晚上也能有充足的光線在陽台喝茶看書聊天～',
      },
      { filename: '10.JPG', description: '' },
      {
        filename: '11.jpg',
        description: '找不到完全符合要求的流理臺，靈機一動不如自己DIY',
      },
      { filename: '12.jpg', description: '' },
      { filename: '13.jpg', description: '' },
      { filename: '14.JPG', description: '自製流理臺完工！' },
    ],
  },

  '/yong-ji/': {
    slug: '/yong-ji/',
    ending: '',
    images: [
      {
        filename: '6.jpg',
        description: '這次用近幾年流行起來的仿清水模塗料~一改單調的牆面',
      },
      { filename: '7.jpg', description: '' },

      {
        filename: '5.jpg',
        description:
          '咦？換了大的桌子，空間反而看起來更大呢？，啊！原來是在牆面和樑柱上動了手腳呀，好比化妝要打鼻影上高光一樣，將原本單一顏色的牆，利用深淺和跳色營造出空間層次感，冰冷的磁磚地板被我們換上溫暖質感的木紋地板！',
      },
      { filename: '4.jpg', description: '換上軌道燈讓廚房光源更充足' },
      {
        filename: '2.jpg',
        description: '廚房空間整面大窗戶 替窗戶換上量身訂製黑百葉',
      },
    ],
  },

  '/zhong-li-renovation/': {
    slug: '/zhong-li-renovation/',
    ending: '',
    images: [
      { filename: 'zir_001.jpg', description: '' },
      { filename: 'zir_002.jpg', description: '' },
      { filename: 'zir_003.jpg', description: '' },
      { filename: 'zir_004.jpg', description: '' },
      { filename: 'zir_005.jpg', description: '' },
      { filename: 'zir_006.jpg', description: '' },
      { filename: 'zir_007.jpg', description: '' },
      { filename: 'zir_008.jpg', description: '' },
      { filename: 'zir_009.jpg', description: '' },
      { filename: 'zir_010.jpg', description: '' },
      { filename: 'zir_011.jpg', description: '' },
      { filename: 'zir_012.jpg', description: '' },
      { filename: 'zir_013.jpg', description: '' },
      { filename: 'zir_014.jpg', description: '' },
      { filename: 'zir_015.jpg', description: '' },
      { filename: 'zir_016.jpg', description: '' },
    ],
  },
  '/cozy-house-renovation/': {
    slug: '/cozy-house-renovation/',
    ending: '',
    images: [
      { filename: 'lsb_001.jpg', description: '' },
      { filename: 'lsb_002.jpg', description: '' },
      { filename: 'lsb_003.jpg', description: '' },
      { filename: 'lsb_004.jpg', description: '' },
      { filename: 'lsb_005.jpg', description: '' },
      { filename: 'lsb_006.jpg', description: '' },
      { filename: 'lsb_007.jpg', description: '' },
      { filename: 'lsb_008.jpg', description: '' },
      { filename: 'lsb_009.jpg', description: '' },
      { filename: 'lsb_010.jpg', description: '' },
      { filename: 'lsb_011.jpg', description: '' },
      { filename: 'lsb_012.jpg', description: '' },
      { filename: 'lsb_013.jpg', description: '' },
      { filename: 'lsb_014.jpg', description: '' },
      { filename: 'lsb_015.jpg', description: '' },
    ],
  },
  '/office-renovation/': {
    slug: '/office-renovation/',
    ending: '',
    images: [
      { filename: 'xh._01.jpg', description: '' },
      { filename: 'xh._02.jpg', description: '' },
      { filename: 'xh._03.jpg', description: '' },
      { filename: 'xh._04.jpg', description: '' },
      { filename: 'xh._05.jpg', description: '' },
      { filename: 'xh._06.jpg', description: '' },
      { filename: 'xh._08.jpg', description: '' },
      { filename: 'xh._09.jpg', description: '' },
      { filename: 'xh._10.jpg', description: '' },
      { filename: 'xh._11.jpg', description: '' },
      { filename: 'xh._12.jpg', description: '' },
      { filename: 'xh._13.jpg', description: '' },
      { filename: 'xh._15.jpg', description: '' },
      { filename: 'xh._16.jpg', description: '' },
      { filename: 'xh._17.jpg', description: '' },
      { filename: 'xh._18.jpg', description: '' },
      { filename: 'xh._19.jpg', description: '' },
      { filename: 'xh._20.jpg', description: '' },
    ],
  },
  '/wugu-cozy-house/': {
    slug: '/wugu-cozy-house/',
    ending: '',
    images: [
      { filename: '001.jpg', description: '' },
      { filename: '002.jpg', description: '' },
      { filename: '003.jpg', description: '' },
      { filename: '004.jpg', description: '' },
      { filename: '005.jpg', description: '' },
      { filename: '006.jpg', description: '' },
      { filename: '007.jpg', description: '' },
      { filename: '008.jpg', description: '' },
      { filename: '010.jpg', description: '' },
      { filename: '011.jpg', description: '' },
      { filename: '012.jpg', description: '' },
      { filename: '014.jpg', description: '' },
      { filename: '015.jpg', description: '' },
      { filename: '016.jpg', description: '' },
      { filename: '017.jpg', description: '' },
      { filename: '019.jpg', description: '' },
    ],
  },
  '/nan-hai/': {
    slug: '/nan-hai/',
    ending: '',
    images: [
      { filename: '南海路01.jpg', description: '' },
      { filename: '南海路02.jpg', description: '' },
      { filename: '南海路03.jpg', description: '' },
      { filename: '南海路04.jpg', description: '' },
      { filename: '南海路05.jpg', description: '' },
      { filename: '南海路06.jpg', description: '' },
      { filename: '南海路07.jpg', description: '' },
      { filename: '南海路08.jpg', description: '' },
      { filename: '南海路09.jpg', description: '' },
      { filename: '南海路10.jpg', description: '' },
      { filename: '南海路11.jpg', description: '' },
      { filename: '南海路12.jpg', description: '' },
      { filename: '南海路13.jpg', description: '' },
      { filename: '南海路14.jpg', description: '' },
      { filename: '南海路15.jpg', description: '' },
      { filename: '南海路16.jpg', description: '' },
      { filename: '南海路17.jpg', description: '' },
      { filename: '南海路18.jpg', description: '' },
    ],
  },
}

export default configs
