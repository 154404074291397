import * as React from 'react'
import { Row, Text, Card } from '@nextui-org/react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { basename } from '../utils'
import { GalleryImageProps, ImageRender } from './types'

/**
 *
 * @example getPriorityMap(true, 2, 5)
 * {
 *   0: true
 *   1: true,
 *   2: true,
 *   3: false
 *   4: false
 * }
 */
const getPriorityMap = (flag: boolean, index: number, length: number) => {
  return Array.from({ length: length }, (_, v) => v).reduce(
    (acc: Record<number, boolean>, i: number) => {
      if (i <= index) {
        acc[i] = flag
      } else {
        acc[i] = false
      }

      return acc
    },
    {}
  )
}

type Props = {
  images: GalleryImageProps[]
  imageRender: ImageRender
}

export default ({ images, imageRender }: Props) => {
  const length = images.length

  const [description, setDescription] = React.useState(
    images[0]?.description ?? null
  )
  const [priorities, setPriorities] = React.useState<Record<number, boolean>>(
    () => getPriorityMap(false, length, length)
  )

  const handleSwiperChange = React.useCallback(
    ({ realIndex }: { realIndex: number }) => {
      const windowSize = 4
      const threshold = Math.min(realIndex + windowSize - 1, length - 1)
      const text = images[realIndex]?.description ?? null

      setDescription(text)

      setPriorities(getPriorityMap(true, threshold, length))
    },
    [length]
  )

  return (
    <Card css={{ w: '100%', h: '540px' }}>
      <Card.Body css={{ p: 0 }}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          className="w-full h-full"
          navigation
          pagination={{
            dynamicBullets: true,
          }}
          slidesPerView={1}
          onSlideChange={handleSwiperChange}
        >
          {images.map(({ id, url }, index) => (
            <SwiperSlide key={id}>
              {imageRender({
                id,
                url,
                alt: basename(id),
                priority: priorities[index] ?? false,
              })}
            </SwiperSlide>
          ))}
        </Swiper>
      </Card.Body>

      {description && (
        <Card.Footer
          isBlurred
          css={{
            position: 'absolute',
            bgBlur: '#ffffff66',
            borderTop: '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
            bottom: 0,
            paddingBottom: 36,
            zIndex: 1,
          }}
        >
          <Row wrap="wrap" justify="space-between" align="center">
            <Text color="black">{description}</Text>
          </Row>
        </Card.Footer>
      )}
    </Card>
  )
}
