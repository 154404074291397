import { Link } from 'gatsby'
import { Card, Grid, Text } from '@nextui-org/react'
import { getCasePage } from 'utils/page'
import LeftArrowIcon from 'ui/Icons/LeftArrowIcon'
import RightArrowIcon from 'ui/Icons/RightArrowIcon'
import { MarkdownRemark } from './types'

type NavigatorProps = {
  next?: MarkdownRemark
  previous?: MarkdownRemark
}

type NavigatorItemProps = {
  isPrevious?: boolean
  hint: string
  post: MarkdownRemark
}

const NavigatorItem = ({
  isPrevious = false,
  hint,
  post,
}: NavigatorItemProps) => {
  const {
    fields: { slug },
    frontmatter: { title },
  } = post

  return (
    <Grid xs={12} sm={6}>
      <Card isHoverable variant="bordered">
        <Link to={getCasePage(slug)} className="text-gray-500 text-sm p-4">
          <span className="text-gray-400 text-sm flex items-center">
            {isPrevious && <LeftArrowIcon />}
            <Text size={14} weight="normal">
              {hint}
            </Text>
            {!isPrevious && <RightArrowIcon />}
          </span>

          <Text h4 size={16} className="mt-1" weight="normal">
            {title}
          </Text>
        </Link>
      </Card>
    </Grid>
  )
}

const Navigator = ({ next, previous }: NavigatorProps) => {
  return (
    <>
      {(Boolean(next) || Boolean(previous)) && (
        <Grid.Container
          gap={1}
          justify={Boolean(previous) ? 'space-between' : 'flex-end'}
        >
          {previous && (
            <NavigatorItem
              isPrevious={true}
              post={previous}
              hint="閱讀上一篇"
            />
          )}
          {next && <NavigatorItem post={next} hint="閱讀下一篇" />}
        </Grid.Container>
      )}
    </>
  )
}

export default Navigator
