import React from 'react'
import { Modal } from '@nextui-org/react'
import ImageSwiper from './ImageSwiper'
import { GalleryImageProps, ImageRender } from './types'

export const useImageViewer = () => {
  const [visible, setVisible] = React.useState(false)

  const handleImageViewerOpen = React.useCallback(() => {
    setVisible(true)
  }, [])

  const handleImageViewerClose = React.useCallback(() => {
    setVisible(false)
  }, [])

  return {
    visible,
    handleImageViewerOpen,
    handleImageViewerClose,
  }
}

type Props = {
  images: GalleryImageProps[]
  imageRender: ImageRender
  visible: boolean
  handleImageViewerClose: () => void
}

export default ({
  images,
  imageRender,
  visible,
  handleImageViewerClose,
}: Props) => {
  return (
    <Modal
      blur
      noPadding
      aria-labelledby="modal-title"
      open={visible}
      width="720px"
      closeButton
      onClose={handleImageViewerClose}
      css={{
        padding: 0,
      }}
    >
      <Modal.Body>
        <ImageSwiper images={images} imageRender={imageRender} />
      </Modal.Body>
    </Modal>
  )
}
