import { Card, Grid } from '@nextui-org/react'
import { GalleryImageProps, ImageRender } from './types'
import { basename } from '../utils'

type ImageGridProps = {
  image: GalleryImageProps
  imageRender: ImageRender
  handleImageViewerOpen: () => void
}

export const ImageGrid = ({
  image: { id, url },
  imageRender,
  handleImageViewerOpen,
}: ImageGridProps) => (
  <Card isHoverable isPressable onPress={handleImageViewerOpen}>
    <Card.Body css={{ p: 0 }}>
      {imageRender({
        id,
        url,
        alt: basename(id),
        priority: true,
      })}
    </Card.Body>
  </Card>
)

export type Props = {
  images: GalleryImageProps[]
  imageRender: ImageRender
  imageHeight: number
  handleImageViewerOpen: () => void
}

/**
 * ImageGrids are grids for desktop devices
 * Will return "null" if images are blank
 */
export default ({
  images,
  imageRender,
  imageHeight,
  handleImageViewerOpen,
}: Props) => {
  const fullHeight = imageHeight * 2

  if (images.length <= 0) return null

  const cover = images[0]
  const scenes = images.slice(1, 5)

  return (
    <>
      <Grid.Container gap={1} justify="flex-start" className="w-3/6">
        <Grid xs={12} css={{ h: fullHeight }}>
          <ImageGrid
            image={cover}
            imageRender={imageRender}
            handleImageViewerOpen={handleImageViewerOpen}
          />
        </Grid>
      </Grid.Container>
      <Grid.Container gap={1} justify="flex-start" className="w-3/6">
        {scenes.map((image) => (
          <Grid xs={6} key={image.id} css={{ h: imageHeight }}>
            <ImageGrid
              image={image}
              imageRender={imageRender}
              handleImageViewerOpen={handleImageViewerOpen}
            />
          </Grid>
        ))}
      </Grid.Container>
    </>
  )
}
