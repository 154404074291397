import { graphql, PageProps } from 'gatsby'
import { Text, Spacer } from '@nextui-org/react'
import Seo from 'components/Seo'

import CategoryTag from 'components/CategoryTag'
import Bio from 'components/Bio'
import BlogPlainTemplate from './blog-plain'
import BlogViewerTemplate from './blog-viewer'
import Navigator from './Navigator'
import configs from './configs'
import { BlogType, BlogPageData, PageContext } from './types'
import { getCasePage } from 'utils/page'

import './style.css'
import 'swiper/css'
import 'ui/swiper/swiper-pagination.css'
import 'ui/swiper/swiper-navigation.css'

type ContentProps = {
  data: BlogPageData
  pageContext: PageContext
}

const Content = ({ data, pageContext }: ContentProps) => {
  const post = data.markdownRemark

  const {
    frontmatter: { title, date, categories, type },
  } = post

  const { previous, next } = pageContext
  const config = configs[pageContext.slug]

  return (
    <>
      <section className="mb-8">
        <Text h1 size={32} margin="0" weight="normal">
          {title}
        </Text>
        <Text className="mb-4" color="$accents8">
          {date}
        </Text>

        {categories.map((category, index) => (
          <CategoryTag key={`category-tag-${index}`} text={category} />
        ))}
      </section>

      {type === BlogType.Viewer ? (
        <BlogViewerTemplate post={data.markdownRemark} config={config} />
      ) : (
        <BlogPlainTemplate post={data.markdownRemark} />
      )}
      <Spacer y={1} />
      <Navigator next={next} previous={previous} />
      <hr className="my-8" />
      <Bio />
    </>
  )
}

const BlogPostTemplate = ({
  data,
  pageContext,
}: PageProps<BlogPageData, PageContext>) => {
  return (
    <div className="max-w-3xl mx-auto py-8 px-4">
      <Content data={data} pageContext={pageContext} />
    </div>
  )
}

export const Head = ({
  data,
  pageContext,
}: PageProps<BlogPageData, PageContext>) => {
  const {
    site: { siteMetadata },
    markdownRemark: {
      excerpt,
      frontmatter: { title },
    },
    cover,
  } = data

  // Take blog excerpt or title + site title by default
  const description = excerpt || [title, siteMetadata.title].join('｜')

  return (
    <Seo
      path={getCasePage(pageContext.slug)}
      title={title}
      description={description}
      imageUrl={cover?.childImageSharp.original.src}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostTemplateById($id: String!, $slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        type
        categories
      }
    }

    cover: file(
      relativeDirectory: { regex: $slug }
      name: { eq: "cover__portrait" }
    ) {
      childImageSharp {
        id
        original {
          src
        }
      }
    }
  }
`
