import * as React from 'react'
import { Text, Image } from '@nextui-org/react'
import ImageGallery, {
  GalleryImageProps,
  ImageRenderProps,
} from 'ui/ImageGallery'
import { BlogPageData } from './types'
import { PostConfig } from './configs'
import { getImageUrl } from 'utils/gcp'

const galleryImagesAdapter = (
  slug: string,
  images: PostConfig['images']
): GalleryImageProps[] =>
  images.map(({ filename, description }) => ({
    id: filename,
    url: getImageUrl(slug, filename),
    description,
  }))

const BlogViewerTemplate = ({
  post,
  config: { slug, images, ending },
}: {
  post: BlogPageData['markdownRemark']
  config: PostConfig
}) => {
  const resolved = galleryImagesAdapter(slug, images)

  const imageRender = React.useCallback(({ url, alt }: ImageRenderProps) => {
    return (
      <Image objectFit="cover" src={url} alt={alt} width="100%" height="100%" />
    )
  }, [])

  return (
    <>
      <article
        dangerouslySetInnerHTML={{
          __html: post.html,
        }}
      />
      <ImageGallery images={resolved} imageRender={imageRender} />

      {ending && <Text className="mt-5">{ending}</Text>}
    </>
  )
}

export default BlogViewerTemplate
